import React from 'react'
import Layout from '../../components/layouts/blog'
import PostCard from '../../components/PostCard'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

function Index({ data }) {
	return (
		<>
			<Helmet>
				<title>Classify — {data.wpCategory.name}</title>
				<meta
					name="description"
					content="Stories, guides and ideas written to broaden perspectives and provide helpful insights. 🔥"
				/>
				<link
					rel="canonical"
					href={
						'https://classify.org.uk/blog/' + data.wpCategory.slug
					}
				/>

				<meta property="og:title" content="Classify — Blog" />
				<meta
					property="og:url"
					content={
						'https://classify.org.uk/blog/' + data.wpCategory.slug
					}
				/>
				<meta
					property="og:image"
					content="https://i.imgur.com/ODTJzzm.png"
				/>
				<meta
					property="og:description"
					content="Stories, guides and ideas written to broaden perspectives and provide helpful insights. 🔥"
				/>
				<meta property="og:type" content="website" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@classifyapp" />
				<meta property="twitter:title" content="Classify — Blog" />
				<meta
					name="twitter:image"
					content="https://i.imgur.com/ODTJzzm.png"
				/>
				<meta
					property="twitter:description"
					content="Stories, guides and ideas written to broaden perspectives and provide helpful insights. 🔥"
				/>
			</Helmet>
			<Layout>
				{data.wpCategory.posts.nodes.map((post) => (
					<PostCard
						title={post.title}
						slug={post.slug}
						category={data.wpCategory.name}
						author={post.author.node}
						imageUrl={
							post.blogContent
								? post.blogContent.topImage?.sourceUrl
								: ''
						}
						key={post.slug}
					/>
				))}
			</Layout>
		</>
	)
}

export const pageQuery = graphql`
	query ($catSlug: String!) {
		wpCategory(slug: { eq: $catSlug }) {
			name
			slug
			posts {
				nodes {
					slug
					title
					author {
						node {
							name
							avatar {
								url
							}
						}
					}
					blogContent {
						topImage {
							sourceUrl
							altText
						}
					}
				}
			}
		}
	}
`

export default Index
