import React from 'react';
import Layout from "../index"
import Blogbar from "../../../components/navigation/blogbar"
import Container from "../../../components/Container"
import AlertConfig from "../../../ALERTBAR_CONFIG"
import Subscribe from '../../Subscribe';

function index(props) {
  return (
    <Layout>
      <Blogbar />
      <section id="weekly-tab" style={{marginTop: AlertConfig.enabled ? '160px' : '120px'}}>
        <Container no-vertical>
          <div className="py-16 text-center flex flex-col items-center">
            <h1 className="text-black pb-8 font-college font-medium">THE WEEKLY TAB</h1>
            <p className="text-black-alt text-xl md:w-96 pb-8">Stories, guides and ideas written to broaden perspectives and provide helpful insights. 🔥</p>
            <Subscribe />
          </div>
        </Container>
      </section>
      <section id="posts">
        <Container no-vertical>
          <div className="grid lg:grid-cols-2 gap-8 mb-16">
            {props.children}
          </div>
        </Container>
      </section>
    </Layout>
  );
}

export default index;