import { Link, StaticQuery, graphql } from 'gatsby'
import React from 'react'
import AlertConfig from '../../ALERTBAR_CONFIG'

function index() {
	return (
		<StaticQuery
			query={graphql`
				query {
					allWpCategory(
						filter: { slug: { nin: ["updates", "uncategorized"] } }
					) {
						nodes {
							name
							slug
						}
					}
				}
			`}
			render={(data) => (
				<nav
					className="bg-white fixed top-0 left-0 right-0 py-4 flex items-center justify-center z-10"
					style={{
						height: '60px',
						borderBottom: 'solid 1px #F5F5F5',
						marginTop: AlertConfig.enabled ? '98px' : '58px',
					}}
				>
					<ul className="flex items-center justify-between text-black-faded">
						<li className="mx-4 hover:text-black">
							<Link to="/blog" activeClassName="text-black">
								Latest
							</Link>
						</li>
						{data.allWpCategory.nodes.map((c, index) => (
							<li className="mx-4 hover:text-black" key={index}>
								<Link
									to={`/blog/${c.slug}`}
									activeClassName="text-black"
									key={index}
								>
									{c.name}
								</Link>
							</li>
						))}
					</ul>
				</nav>
			)}
		/>
	)
}

export default index
