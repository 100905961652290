import { Link } from 'gatsby'
import React from 'react'

function PostCard({ title, category, imageUrl, author, slug }) {
	return (
		<Link
			to={`/blog/${slug}`}
			state={{ referrer: 'Blog' }}
			className="bg-white rounded-2xl transition-shadow duration-300 shadow-lg hover:shadow-xl"
		>
			<div
				className="aspect-w-16 aspect-h-9 bg-purple bg-cover bg-center rounded-t-2xl"
				style={{ backgroundImage: `url(${imageUrl})` }}
			/>
			<div className="flex rounded-b-2xl p-8">
				<div className="flex flex-col flex-grow">
					<span className="font-bold lg:font-regular">{title}</span>
					<span className="flex items-center font-regular text-sm capitalize mt-4 whitespace-nowrap">
						<img
							className="rounded-full mr-2"
							src={author.avatar.url}
							alt="Author avatar"
							height="20"
							width="20"
						/>
						{author.name}
					</span>
				</div>
				<div className="hidden sm:flex items-center justify-center text-purple uppercase text-sm font-bold">
					{category}
				</div>
			</div>
		</Link>
	)
}

export default PostCard
